import { VFC } from 'react'
import styled, { css } from 'styled-components'
import { AttentionContainerProps } from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const AttentionContainer: VFC<AttentionContainerProps> = ({ children, type }) => (
  <StyledAttentionContainer type={type}>{children}</StyledAttentionContainer>
)

AttentionContainer.defaultProps = {
  type: 'normal',
}

const StyledAttentionContainer = styled.div<Partial<AttentionContainerProps>>`
  background-color: ${colorConfig.bgAttentionColor};

  ${({ type }) =>
    type === 'normal'
      ? css`
          padding: 1.6rem;

          ${breakpointUp('md')} {
            padding: 4rem 3rem;
          }
        `
      : type === 'iframe'
      ? css`
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 56.25%;
          }

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            padding: 2.4rem;

            ${breakpointUp('md')} {
              padding: 6rem;
            }
          }

          ${breakpointDown('sm')} {
            margin-left: -2.4rem;
            margin-right: -2.4rem;
          }

          ${breakpointUp('md')} {
            padding: 6rem;
          }
        `
      : ''}
`
