import { VFC } from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import {
  TextThemeColorProps,
  BreakPointHiddenBrProps,
  VisuallyHiddenProps,
  ItemContainerProps,
  TextLinkProps,
  AnchorAdjusterProps,
} from '@/components/types'
import { breakpointDown, breakpointUp } from '@/utils/breakpoints'
import { colorConfig } from '@/utils/styleConfig'

export const TextThemeColor: VFC<TextThemeColorProps> = ({ children }) => (
  <StyledTextThemeColor>{children}</StyledTextThemeColor>
)

const StyledTextThemeColor = styled.span`
  color: ${colorConfig.themeColor};
`

export const VisuallyHidden: VFC<VisuallyHiddenProps> = ({ children }) => (
  <StyledVisuallyHidden>{children}</StyledVisuallyHidden>
)

const StyledVisuallyHidden = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const BreakPointHiddenBr: VFC<BreakPointHiddenBrProps> = ({ direction, breakpoint }) => (
  <Br direction={direction} breakpoint={breakpoint} />
)

const Br = styled.br<Partial<BreakPointHiddenBrProps>>`
  ${({ direction, breakpoint }) => {
    switch (direction) {
      case 'up':
        return css`
          ${breakpointUp(breakpoint)} {
            display: none !important;
            visibility: hidden;
          }
        `

      case 'down':
        return css`
          ${breakpointDown(breakpoint)} {
            display: none !important;
            visibility: hidden;
          }
        `
      default:
        break
    }
  }}
`

export const ItemContainer: VFC<ItemContainerProps> = ({ children, margin }) => (
  <StyledItemContainer margin={margin}>{children}</StyledItemContainer>
)

const StyledItemContainer = styled.div<Partial<ItemContainerProps>>`
  ${({ margin }) => {
    switch (margin) {
      case 'sm':
        return css`
          margin: 2.4rem 0;
        `

      case 'md':
        return css`
          margin: 4rem 0;
        `

      case 'lg':
        return css`
          margin: 6.4rem 0;
          }
        `
      default:
        break
    }
  }}
`

export const TextLink: VFC<TextLinkProps> = ({ label, link, target }) => (
  <TextLinkContainer>
    {target === '_blank' ? (
      <StyledExternalLink href={link} target="_blank" rel="noopener">
        {label}
      </StyledExternalLink>
    ) : (
      <Link to={link}>
        <StyledInnerLink>{label}</StyledInnerLink>
      </Link>
    )}
  </TextLinkContainer>
)

const TextLinkContainer = styled.span<Partial<TextLinkProps>>`
  display: inline;
  color: ${colorConfig.themeColor};
`

const StyledInnerLink = styled.span`
  position: relative;
  box-shadow: 0 0.2rem 0 -0.1rem ${colorConfig.themeColor};
  margin-left: 2.4rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -2rem;
    width: 1.7rem;
    height: 1.7rem;
    background: top / 100% no-repeat url(/images/case/icon_arrow-r.svg);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 -0.1rem ${colorConfig.themeColor};
    }
  }
`

const StyledExternalLink = styled.a<Partial<TextLinkProps>>`
  position: relative;
  box-shadow: 0 0.2rem 0 -0.1rem ${colorConfig.themeColor};
  margin-left: 3.2rem;
  line-break: strict;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -0.1rem;
    left: -2.8rem;
    width: 2.2rem;
    height: 1.8rem;
    background: top / 100% no-repeat url(/images/case/icon_another-window.svg);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 0 -0.1rem ${colorConfig.themeColor};
    }
  }
`

export const AnchorAdjuster: VFC<AnchorAdjusterProps> = ({ id }) => (
  <StyledAnchorAdjuster id={id}></StyledAnchorAdjuster>
)

const StyledAnchorAdjuster = styled.div<AnchorAdjusterProps>`
  position: relative;
  display: block;
  top: -6rem;

  ${breakpointUp('md')} {
    top: -10rem;
  }
`
