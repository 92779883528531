import { combineReducers, createStore } from 'redux'
import { caseTagsFilterReducer } from '@/feature/caseTagsFilter'
import { recruitControlTabsReducer } from '@/feature/recruitControlTabs'
import * as caseTagsFilter from '@/feature/caseTagsFilter'
import * as recruitControlTabs from '@/feature/recruitControlTabs'

const reducer = combineReducers({
  caseTagsFilter: caseTagsFilterReducer,
  recruitControlTabs: recruitControlTabsReducer,
})

export const store = createStore(
  reducer,
  // redux devtools 用の記述
  // typeof window !== 'undefined' &&
  //   window.__REDUX_DEVTOOLS_EXTENSION__ &&
  //   window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export const mapStateToProps = state => {
  return {
    selectedCaseTags: state.caseTagsFilter.selectedCaseTags,
    currentTab: state.recruitControlTabs.currentTab,
  }
}

export const mapDispatchToProps = (dispatch: Function) => ({
  addCaseFilter: event => dispatch(caseTagsFilter.add(event)),
  removeCaseFilter: event => dispatch(caseTagsFilter.remove(event)),
  clearCaseFilter: event => dispatch(caseTagsFilter.reset()),
  setRecruitControlTabs: event => dispatch(recruitControlTabs.set(event)),
})
