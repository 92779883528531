/**
 * Actions
 */
export const set = event => ({
  type: 'SET',
  payload: {
    value: event.target.id,
  },
})

/**
 * Reducer
 */
const initialState = {
  currentTab: 'newGraduates',
}

export const recruitControlTabsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET': {
      return {
        currentTab: action.payload.value,
      }
    }
    default:
      return state
  }
}
