/**
 * Actions
 */
export const add = event => ({
  type: 'ADD',
  payload: {
    value: event.currentTarget.value,
  },
})

export const remove = event => ({
  type: 'REMOVE',
  payload: {
    value: event.currentTarget.value,
  },
})

export const reset = () => ({
  type: 'RESET',
})

/**
 * Reducer
 */
const initialState = {
  selectedCaseTags: [],
}

export const caseTagsFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD': {
      return {
        selectedCaseTags: [...state.selectedCaseTags, action.payload.value],
      }
    }
    case 'REMOVE': {
      return {
        selectedCaseTags: [...state.selectedCaseTags.filter(tag => tag !== action.payload.value)],
      }
    }
    case 'RESET': {
      return {
        selectedCaseTags: [],
      }
    }
    default:
      return state
  }
}
