/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'ress'
import '@fontsource/barlow/700.css'
import wrapWithProvider from "@/components/WrapWithProvider"

export const wrapRootElement = wrapWithProvider