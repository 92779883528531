export const breakpoints = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const rootFontSize = {
  sm: '2.6666667vw', // (20/750) * 100
  md: '10px',
  lg: '10px',
  xl: '10px',
}

export type Breakpoints = keyof typeof breakpoints

const color: { [key: string]: string } = {
  white: '#fff',
  black: '#333',
  trueGray100: '#f2f2f2',
  trueGray300: '#ccc',
  trueGray400: '#a3a3a3',
  red50: '#fff1f1',
  red600: '#e60000',
}

export const colorConfig: { [key: string]: string } = {
  themeColor: color.red600,
  subColor: color.trueGray400,
  textMainColor: color.black,
  textSubColor: color.white,
  bgColor: color.white,
  bgSubColor: color.trueGray100,
  bgAttentionColor: color.red50,
  borderColor: color.trueGray300,
}

export const caseColor = {
  museum: {
    gradation1: '#ffdfc2',
    gradation2: '#ffb5c3',
  },
  themePark: {
    gradation1: '#fff4a0',
    gradation2: '#ffa989',
  },
  pr: {
    gradation1: '#baffcb',
    gradation2: '#acccff',
  },
  shoppingComplex: {
    gradation1: '#f1ed92',
    gradation2: '#afe485',
  },
  education: {
    gradation1: '#caffbe',
    gradation2: '#91f2f6',
  },
  event: {
    gradation1: '#c2e6ff',
    gradation2: '#b7b7ff',
  },
  original: {
    gradation1: '#ffbfc0',
    gradation2: '#ffbdff',
  },
  office: {
    gradation1: '#c1cfff',
    gradation2: '#d7adff',
  },
  other: {
    gradation1: '#ddb2ff',
    gradation2: '#ffbded',
  },
}

export const zIndex = {
  header: 3,
  navigation: 2,
  footer: 1,
  layout: 0,
  background: -1,
}

export const mixins = {
  verticalGrid: (grid = 1): string => {
    const vertical = 2.8

    return `${vertical * grid}rem`
  },
  vw: ({ size, viewport = 1024 }): string => {
    return `${(size / viewport) * 100}vw`
  },
  hoverDefault: (color = 'inherit'): string => {
    return `
      @media (hover: hover) {
        opacity: 1;
        transition: opacity 0.2s ease-out;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
          text-decoration: none;
          color: ${color};
        }
      }
    `
  },
  hoverButton: (): string => {
    return `
      @media (hover: hover) {
        transition: 0.2s box-shadow ease-out;

        &:hover {
          box-shadow: 0px 0px 12px rgb(0 0 0 / 40%);
        }
      }
    `
  },
  hoverText: ({ color = colorConfig.themeColor }): string => {
    return `
      @media (hover: hover) {
        transition: color 0.1s ease;

        &:hover {
          color: ${color};
        }
      }
    `
  },
  hoverTextUnderLine: ({ color = 'inherit' }): string => {
    return `
    @media (hover: hover) {
      position: relative;
  
      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${color} !important;
        transform: scaleX(0);
        transition: transform 0.1s ease-out;
        transform-origin: right;
      }
  
      &:hover {
        &::before {
          transition: transform 0.2s ease-out;
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }
    `
  },
  lineClamp: (line = 3): string => {
    return `
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${line};
      -webkit-box-orient: vertical;
    `
  },
}
